<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            append-inner-icon="mdi-magnify"
            color="primary"
            variant="underlined"
            label="Search"
            hide-details
            clearable
            @click:clear="search()"
            style="max-width"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
            test-id="search"
          ></v-text-field>
          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="usersTableColumns" />
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn size="small" color="primary" class="align-self-end" @click="newUser()" test-id="new-user"> New user </v-btn>
          <v-menu v-if="isAdmin" location="bottom left">
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props" density="compact" variant="text" class="ml-4">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="exportUserActivity(moment().subtract(1, 'year').year())">
                <v-list-item-title>{{ `Export user activity - ${moment().subtract(1, "year").year()}` }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportUserActivity(moment().year())">
                <v-list-item-title>{{ `Export user activity - ${moment().year()}` }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>

      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
        :loading="loading"
        :mobile-breakpoint="0"
        @click:row="(event, { item }) => rowClick(item)"
        @contextmenu:row="openContenxMenu"
      >
        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isActive">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.isAdministrator`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isAdministrator">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.groups`]="{ item }">
          <v-chip
            size="small"
            class="ma-1px cursor-pointer"
            v-for="group in item.userGroups"
            v-bind:key="group.userGroupId"
            @click.stop="userGroupToEdit = JSON.parse(JSON.stringify(group))"
            >{{ group.name }}</v-chip
          >
        </template>
        <template v-slot:[`item.lastLoginDate`]="{ item }">
          <span v-if="item.lastLoginDate">{{ moment(item.lastLoginDate).format("lll") }}</span>
        </template>
        <template v-slot:[`item.mfa`]="{ item }">
          <span v-if="item.mfaType">{{ getMfaTypeName(item.mfaType) }}</span>
        </template>
        
        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay position="absolute" :model-value="loading" opacity="0" />
    </v-card>

    <EditUser v-model="userToEdit" v-on:updated="reload" :initTab="userInitTab" />
    <DataTableContextMenu v-model="contextMenuEventItem" />
    <EditUserGroup v-model="userGroupToEdit" v-on:updated="reload" :isDeleteBtn="false" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, toNative } from "vue-facing-decorator";
import User from "@/types/User";
import userResource from "@/resources/UserResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import EditUser from "@/components/users/EditUser.vue";
import UserGroup from "@/types/UserGroup";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import { MultiFactorAuthType } from "@/types/MultiFactorAuthType";
import UserHelper from "@/helpers/userHelper";
import userStorage from "@/services/UserStorageService";
import EditUserGroup from "@/components/users/EditUserGroup.vue";
import userGroupResource from "@/resources/UserGroupResource";
import commonHelper from "@/helpers/commonHelper";
import userProfileService from "@/services/UserProfileService";
import { nextTick } from "vue";
//@ts-ignore
import DataTableFooter from "@/components/common/DataTableFooter.vue";

@Component({
  components: {
    EditUser,
    DataTableContextMenu,
    TableConfiguration,
    EditUserGroup,
    DataTableFooter
  },
})
class Users extends Vue {
  @Prop({ default: null })
  initData!: { userId: number; userGroupId: number; userTab: string } | null;

  moment = moment;

  total = 0;
  items: User[] = [];
  loading = false;
  userGroupToEdit: UserGroup | null = null;
  userInitTab: string | null = null;

  optionsStorageKey = "usersTable";
  itemsPerPage = userStorage.get(this.optionsStorageKey)?.itemsPerPage ?? 15;

  sortBy: { key: string; order: boolean | "asc" | "desc" }[] = userStorage.get(this.optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(this.optionsStorageKey)?.sortBy
    : [{ key: "userId", order: true }];

  page = userStorage.get(this.optionsStorageKey)?.page ?? 1;
  searchTermStorageKey = "userTableSearchTerm";
  searchTerm = userStorage.get(this.searchTermStorageKey) ?? "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;

  userToEdit: User | null = null;

  get isAdmin() {
    return userProfileService.currentUser?.isAdministrator;
  }

  @Watch("itemsPerPage")
  @Watch("sortBy", { deep: true })
  @Watch("page")
  async onPropertyChanged() {
    await nextTick();
    this.getData();
  }

  mounted() {
    if (this.initData?.userId) {
      this.getInitUserById(this.initData?.userId);
    }
    if (this.initData?.userGroupId) {
      this.getInitUserGroupById(this.initData?.userGroupId);
    }
    this.getData();
  }

  getInitUserGroupById(userGroupId: number) {
    userGroupResource
      .getUserGroupById(userGroupId)
      .then((resp) => {
        this.userGroupToEdit = resp.data;
      })
      .catch(userGroupResource.defaultErrorHandler);
  }

  getInitUserById(userId: number) {
    userResource
      .getUserById(userId)
      .then((resp) => {
        this.userInitTab = this.initData?.userTab || null;
        this.userToEdit = resp.data;
      })
      .catch(userResource.defaultErrorHandler);
  }

  selectedHeaders = [];
  headers = [
    { title: "ID", align: "start", key: "userId" },
    { title: "Username", key: "username" },
    { title: "Active", key: "isActive" },
    { title: "Admin", key: "isAdministrator" },
    { title: "Groups", key: "groups" },
    { title: "MFA", key: "mfa" },
    { title: "Last sign in", key: "lastLoginDate" },
  ];

  contextMenuEventItem: any = null;
  openContenxMenu(e: any) {
    this.contextMenuEventItem = e;
  }

  getMfaTypeName(type: MultiFactorAuthType) {
    return UserHelper.getMfaTypeDisplayName(type);
  }

  newUser() {
    this.userToEdit = {
      userId: 0,
      username: "",
      password: "",
      email: "",
      isActive: true,
      mfaType: MultiFactorAuthType.None,
      userGroups: [] as UserGroup[],
      isPasswordUpdateRequired: true,
    } as User;
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Save sorting, filters and search terms
    userStorage.set(this.optionsStorageKey, {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      sortBy: this.sortBy,
    });
    userStorage.set(this.searchTermStorageKey, this.searchTerm);

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      const orderDesc =
        typeof this.sortBy[0].order === "boolean" ? this.sortBy[0].order : this.sortBy[0].order.toString() === "desc";

      userResource
        .getUsersPaged(this.itemsPerPage, this.page, this.searchTerm, this.sortBy[0].key, orderDesc, this.cancelToken)
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
        })
        .catch(userResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData();
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData();
      }, 1000);
    }
  }

  reload() {
    this.getData();
  }

  rowClick(item: User) {
    if (!this.contextMenuEventItem) {
      this.userToEdit = Object.assign({}, item);
    }
  }

  rowClass({ item }: { item: User }) {
    return { class: { "cursor-default text-grey": !item.isActive, "cursor-default": item.isActive } };
  }

  exportUserActivity(year: string) {
    window.open(`${commonHelper.apiHost}/export/user-activity?year=${year}`, "_blank");
  }
}
export default toNative(Users);
</script>

<style scoped></style>
