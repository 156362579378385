<template>
  <div class="mp-wrap d-block d-md-flex flex-column">
    <v-overlay  v-if="loading" position="absolute" class="progress" opacity="0" z-index="101">
      <v-progress-linear indeterminate position="absolute" />
    </v-overlay>
    <div class="mp-title">Active devices (top 2K, last minute)</div>
    <div id="leaflet" class="mp-container flex-grow-1">
      <div id="map"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative, Setup } from "vue-facing-decorator";
import TopDevice from "@/types/TopDevice";
import statsResource from "@/resources/StatsResource";
import axios, { CancelTokenSource } from "axios";
import L from "leaflet";
import MapHelper from "@/helpers/mapHelper";
import { useDisplay, useTheme } from 'vuetify'

@Component({
  name: "Heatmap",
  components: {},
})
class Heatmap extends Vue {
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;
  allowAutoCenter = true;

  geoJson: TopDevice[] = [];

  @Setup(() => useDisplay().mdAndDown)
  isTabletAndDown= useDisplay().mdAndDown
  
  @Setup(() => useTheme().current.value.colors.primary)
  circleColor = useTheme().current.value.colors.primary;

  map: any = null;
  mapCenter = [64, 19];
  markersGroup: any | null = null;
  timer = 0;
  leaflet = {
    url: MapHelper.defaultMapTilesUrl,
    attribution: MapHelper.defaultMapAttr,
  };

  mounted() {
    // Init map
    this.map = L.map("map", {
      dragging: !this.isTabletAndDown,
      touchZoom: true,
      zoomControl: false,
      preferCanvas: true,
      zoom: 5,
      maxZoom: 18,
      minZoom: 3,
      center: this.mapCenter,
      worldCopyJump: true,
      renderer: L.canvas(),
    });

    L.tileLayer(MapHelper.defaultMapTilesUrl, {
      attribution: MapHelper.defaultMapAttr,
    }).addTo(this.map);
  }

  activated() {
    this.getData();
    this.timer = setInterval(() => this.getData(), 60 * 1000);
    this.allowAutoCenter = true;
  }

  deactivated() {
    if (this.timer) {
      const timer = this.timer;
      clearInterval(timer);
    }
  }

  center() {
    if (this.markersGroup && this.markersGroup.getBounds?.()?.isValid()) {
      this.map.fitBounds(this.markersGroup.getBounds());
    }
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      statsResource
        .getTopActiveDevices(this.cancelToken)
        .then((resp) => {
          // Create markers
          const opt = {
            stroke: true,
            color: "rgba(0,0,0,0.4)",
            weight: 1,
            fillColor: this.circleColor,
            fillOpacity: 0.6,
            radius: 3,
            interactive: false,
          };
          const markers = resp.data.map((item) => {
            return L.circleMarker([item.lat, item.lon], opt);
          });

          // Clear and add marker group with markers
          if (this.markersGroup) {
            this.map.removeLayer(this.markersGroup);
          }

          this.markersGroup = L.featureGroup(markers);
          this.map.addLayer(this.markersGroup);

          // Center map
          if (this.allowAutoCenter) {
            this.allowAutoCenter = false; // Only auto center on first time load
            setTimeout(() => this.center(), 10);
          }
        })
        .catch(statsResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }
}

export default toNative(Heatmap);
</script>

<style scoped>
.mp-wrap {
  height: 100%;
  position: relative;
}
.mp-container {
  position: relative;
  height: 100%;
}
.mp-container :deep(.leaflet-layer) {
  filter: grayscale(1);
}
.v-theme--dark .mp-container :deep(.leaflet-layer) {
  filter: invert(1) grayscale(1);
}
.mp-container :deep(.leaflet-container .leaflet-control-attribution) {
  background: rgba(255, 255, 255, 0.75);
}
.v-theme--dark .mp-container :deep(.leaflet-container .leaflet-control-attribution) {
  background: rgba(20, 20, 20, 0.75);
  color: #ccc !important;
}
.v-theme--dark .mp-container :deep(.leaflet-container .leaflet-control-attribution a) {
  color: #ccc !important;
}
.mp-container .leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 0;
  background: transparent;
}
.mp-title {
  text-align: center;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
}
.progress :deep(.v-overlay__content) {
  width: 25%;
}
</style>
