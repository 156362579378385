<template>
  <div class="chart">
    <v-overlay v-if="loading" position="absolute" class="progress" opacity="0" z-index="101">
      <v-progress-linear indeterminate position="absolute" />
    </v-overlay>
    <v-chart :option="option" autoresize :class="{ 'opacity-50' : loading }" />
  </div>
</template>

<script lang="ts">
import { use, ComposeOption } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, BarSeriesOption } from "echarts/charts";
import {
  TitleComponentOption,
  TitleComponent,
  TooltipComponentOption,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GridComponentOption,
} from "echarts/components";
import VChart from "vue-echarts";
import { Component, Vue, Watch, toNative, Setup } from "vue-facing-decorator";
import statsResource from "@/resources/StatsResource";
import axios, { CancelTokenSource } from "axios";
import { useTheme } from "vuetify";

use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);
type ECOption = ComposeOption<BarSeriesOption | TitleComponentOption | TooltipComponentOption | GridComponentOption>;

@Component({
  name: "DeviceFirmwareChart",
  components: { VChart },
})
class DeviceFirmwareChart extends Vue {
  timer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;

  activated() {
    this.getData();
    this.timer = setInterval(() => this.getData(), 60 * 1000);
  }

  deactivated() {
    if (this.timer) {
      const timer = this.timer;
      clearInterval(timer);
      this.timer = 0;
    }
  }

  @Setup(() => useTheme())
  theme = useTheme();

  get textColor() {
    return this.theme?.global?.current?.value?.dark ? "#ffffff" : "#333";
  }

  get secondaryColor() {
    return this.theme?.global?.current?.value?.dark ? "#333" : "#ccc";
  }

  @Watch("theme.global.current.value.dark", { deep: true })
  onChangeTheme() {
    if (!Array.isArray(this.option.title) && this.option.title?.textStyle) {
      this.option.title.textStyle.color = this.textColor;
    }

    if (!Array.isArray(this.option.xAxis)) {
      if (this.option.xAxis?.axisLabel) {
        this.option.xAxis.axisLabel.color = this.textColor;
      }

      if (this.option.xAxis?.axisLine?.lineStyle) {
        this.option.xAxis.axisLine.lineStyle.color = this.secondaryColor;
      }
    }

    if (!Array.isArray(this.option.yAxis)) {
      if (this.option.yAxis?.axisLabel) {
        this.option.yAxis.axisLabel.color = this.textColor;
      }
      if (this.option.yAxis?.splitLine?.lineStyle) {
        this.option.yAxis.splitLine.lineStyle.color = this.secondaryColor;
      }
    }
  }

  option: ECOption = {
    textStyle: { fontFamily: "Roboto, sans-serif" },
    grid: { bottom: 35 },

    legend: {
      top: 30,
      textStyle: { color: this.textColor },
    },

    title: {
      text: "Device firmware",
      left: "center",
      textStyle: {
        fontSize: 20,
        fontWeight: 400,
        color: this.textColor,
      },
    },

    xAxis: {
      type: "category",
      data: [],
      axisLine: { lineStyle: { color: this.secondaryColor } },
      axisLabel: { color: this.textColor },
    },

    yAxis: {
      axisLabel: {
        color: this.textColor,
        formatter: (val: number) => `${val / 1000}K`,
      },
      splitLine: { show: true, lineStyle: { color: this.secondaryColor } },
    },

    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
    },

    series: [
      {
        type: "bar",
        stack: "total",
        name: "ProX",
        itemStyle: { color: this.theme.current.value.colors.primary, opacity: 0.75 },
        data: [],
      },

      {
        type: "bar",
        stack: "total",
        name: "Pro",
        itemStyle: { color: "#f24911", opacity: 0.75 },
        data: [],
      },

      {
        type: "bar",
        stack: "total",
        name: "n/a",
        itemStyle: { color: "#999", opacity: 0.75 },
        data: [],
      },
    ],
  };

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      statsResource
        .getDeviceTypesByVersion(this.cancelToken)
        .then((resp) => {
          const data = resp.data;

          this.option.xAxis = { data: data.map(({ ver }) => ver) };
          const sortArr = ["ProX", "Pro", "n/a"] as const;
          const ProXData = data.map(({ perDeviceType }) => perDeviceType["ProX"] || 0);
          const ProData = data.map(({ perDeviceType }) => perDeviceType["Pro"] || 0);
          const naData = data.map(({ perDeviceType }) => perDeviceType["n/a"] || 0);
          const ProXSeries = (this.option.series as BarSeriesOption[]).find(({ name }) => name === "ProX");
          const ProSeries = (this.option.series as BarSeriesOption[]).find(({ name }) => name === "Pro");
          const naSeries = (this.option.series as BarSeriesOption[]).find(({ name }) => name === "n/a");

          this.option.series = [
            { ...ProXSeries, data: ProXData },
            { ...ProSeries, data: ProData },
            { ...naSeries, data: naData },
          ];
        })
        .catch(statsResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }
}

export default toNative(DeviceFirmwareChart)
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress :deep(.v-overlay__content) {
  width: 25%;
}
</style>
