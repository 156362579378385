import ResourceBase from "@/resources/ResourceBase";
import Device from "@/types/Device";
import DeviceIdentity from "@/types/DeviceIdentity";
import Issue from "@/types/Issue";
import DeviceLocation from "@/types/DeviceLocation";
import DeviceVersion from "@/types/DeviceVersion";
import EntityChangeRecord from "@/types/EntityChangeRecord";
import IssueDeviceStatusListItem from "@/types/IssueDeviceStatusListItem";
import GenericResponse from "@/types/GenericResponse";
import MobileNetworkProviderStatus from "@/types/MobileNetworkProviderStatus";
import PagedData from "@/types/PagedData";
import { DeviceType } from "@/types/DeviceType";
import Comment from "@/types/Comment";
import PagedComments from "@/types/PagedComments";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import MobileNetworkUsageType from "@/types/MobileNetworkUsageType";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import { DeviceStatus } from "@/types/DeviceStatus";

class DeviceResource extends ResourceBase {
    getDevicesPaged(
        itemsPerPage: number,
        pageNumber: number,
        searchTerm: string | undefined,
        orderBy: string | undefined = undefined,
        orderDesc: boolean = false,
        typeOf?: DeviceType[],
        isBlocked?: boolean,
        status?: DeviceStatus[],
        isUpdateAllowed?: boolean,
        cancelToken: any | undefined = undefined
    ): Promise<AxiosResponse<PagedData<Device>>> {
        const config = {
            params: {
                itemsPerPage,
                pageNumber,
                search: searchTerm,
                orderBy,
                orderDesc,
                typeOf,
                isBlocked,
                withStatus: status,
                isUpdateAllowed,
            },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<Device>>("/devices", config);
    }

    updateDevice(device: Device, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.put<GenericResponse>(`/devices/${device.deviceId}`, device, config);
    }

    deleteDevice(deviceId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`/devices/${deviceId}`, config);
    }

    getDeviceById(deviceId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<Device>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<Device>(`/devices/${deviceId}`, config);
    }

    getDeviceIdentityById(deviceId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<DeviceIdentity>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<DeviceIdentity>(`/devices/${deviceId}/identity`, config);
    }

    getDeviceChangeHistory(
        deviceId: number,
        cancelToken: any | undefined = undefined
    ): Promise<AxiosResponse<EntityChangeRecord[]>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<EntityChangeRecord[]>(`/devices/${deviceId}/change-history`, config);
    }

    getLastKnownDeviceLocation(
        deviceId: number,
        cancelToken: any | undefined = undefined
    ): Promise<AxiosResponse<DeviceLocation>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<DeviceLocation>(`/devices/${deviceId}/last-known-device-location`, config);
    }

    getLastKnownDeviceVersion(
        deviceId: number,
        cancelToken: any | undefined = undefined
    ): Promise<AxiosResponse<DeviceVersion>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<DeviceVersion>(`/devices/${deviceId}/last-known-device-version`, config);
    }

    getDeviceLocationHistory(
        deviceId: number,
        fromDate: Date,
        toDate: Date,
        cancelToken: any | undefined = undefined
    ): Promise<AxiosResponse<DeviceLocation[]>> {
        const config = {
            params: {
                fromDate: fromDate,
                toDate: toDate,
            },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<DeviceLocation[]>(`/devices/${deviceId}/device-location-history`, config);
    }

    getMobileNetworkUsage(
        deviceId: number,
        cancelToken: any | undefined = undefined
    ): Promise<AxiosResponse<MobileNetworkUsageType>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<MobileNetworkUsageType>(`/devices/${deviceId}/mobile-network-usage`, config);
    }

    getMobileNetworkProviderStatus(
        deviceId: number,
        cancelToken: any | undefined = undefined
    ): Promise<AxiosResponse<MobileNetworkProviderStatus>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<MobileNetworkProviderStatus>(
            `/devices/${deviceId}/mobile-network-provider-status`,
            config
        );
    }

    deactivateSimCard(deviceId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.put<GenericResponse>(`/devices/${deviceId}/deactivate-simcard`, config);
    }

    activateSimCard(deviceId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.put<GenericResponse>(`/devices/${deviceId}/activate-simcard`, config);
    }

    getFwUpdateUrl(deviceId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<string | undefined>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<string | undefined>(`/devices/${deviceId}/fw-update-url`, config);
    }

    getIssuesByDevicePaged(
        deviceId: number,
        itemsPerPage: number,
        pageNumber: number,
        searchTerm: string | undefined,
        orderBy: string | undefined = undefined,
        orderDesc: boolean = false,
        cancelToken: any | undefined = undefined
    ): Promise<AxiosResponse<PagedData<Issue>>> {
        const config = {
            params: {
                itemsPerPage: itemsPerPage,
                pageNumber: pageNumber,
                search: searchTerm,
                orderBy: orderBy,
                orderDesc: orderDesc,
            },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<Issue>>(`/devices/${deviceId}/issues`, config);
    }

    getIssueStatusesByDeviceId(deviceId?: number, cancelToken?: any): Promise<AxiosResponse<IssueDeviceStatusListItem[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.get<IssueDeviceStatusListItem[]>(`/devices/${deviceId}/issues/status`, config);
    }

    addDeviceComment(deviceId: number, content: string, cancelToken?: any): Promise<AxiosResponse<Comment>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.post<Comment>(`/devices/${deviceId}/comments`, { content }, config);
    }

    getDeviceComments(
        deviceId: number,
        limit?: number,
        createdBefore?: Date | string,
        createdAfter?: Date | string
    ): Promise<AxiosResponse<PagedComments>> {
        const config = {
            params: { limit, createdAfter, createdBefore },
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedComments>(`/devices/${deviceId}/comments`, config);
    }

    updateDeviceComment(deviceId: number, comment: Comment, cancelToken?: any): Promise<AxiosResponse<Comment>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.put<Comment>(
            `/devices/${deviceId}/comments/${comment.commentId}`,
            { content: comment.content },
            config
        );
    }

    deleteComment(deviceId: number, commentId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`devices/${deviceId}/comments/${commentId}`, config);
    }

    getCommentCount(deviceId: number, cancelToken?: any ): Promise<AxiosResponse<{total: number}>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.get<{total: number}>(`/devices/${deviceId}/comments/count`, config);
    }

    getIssueCount(deviceId: number, deviceStatus:IssueDeviceStatus[] = [IssueDeviceStatus.New, IssueDeviceStatus.Active], cancelToken?: any ): Promise<AxiosResponse<{total: number}>> {
        const config = {       
            params: { deviceStatus },
            cancelToken: cancelToken?.token 
        } as AxiosRequestConfig;

        return this.apiClient.get<{total: number}>(`/devices/${deviceId}/issues/count`, config);
    }

    exportDevices(queryParams: string[] | undefined, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { 
            cancelToken: cancelToken?.token,
         } as AxiosRequestConfig;
        return this.apiClient.get<GenericResponse>(`/devices/export?${queryParams?.join("&")}`, config);
    }
}

const deviceResource = new DeviceResource();
export default deviceResource;
