import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-grow-1 align-end" }
const _hoisted_2 = { class: "flex-grow-0 pr-2 select-auto-width" }
const _hoisted_3 = { class: "text-right align-self-end mt-2 mt-sm-0" }
const _hoisted_4 = { class: "pre-wrap" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "pre-wrap" }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_TableConfiguration = _resolveComponent("TableConfiguration")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_DataTableFooter = _resolveComponent("DataTableFooter")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_EditCustomer = _resolveComponent("EditCustomer")!
  const _component_DataTableContextMenu = _resolveComponent("DataTableContextMenu")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-block d-sm-flex" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.selectedSearchFields,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSearchFields) = $event)),
                      _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchTerm ? _ctx.search(true) : false))
                    ],
                    items: _ctx.searchFields,
                    label: "Fields",
                    "hide-details": "",
                    variant: "underlined",
                    color: "primary"
                  }, null, 8, ["modelValue", "items"])
                ]),
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.searchTerm,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchTerm) = $event)),
                  "append-inner-icon": "mdi-magnify",
                  label: "Search for",
                  "hide-details": "",
                  clearable: "",
                  prefix: ":",
                  style: {},
                  "onClick:clear": _cache[3] || (_cache[3] = ($event: any) => (_ctx.search())),
                  onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.search())),
                  onKeypress: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.search(true)), ["enter"])),
                  variant: "underlined",
                  color: "primary"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_btn, {
                  size: "x-small",
                  variant: "text",
                  icon: "",
                  class: "align-self-end ml-4",
                  onClick: _ctx.reload,
                  disabled: _ctx.loading,
                  title: "Refresh"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { size: "24" }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-reload")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"]),
                _createVNode(_component_TableConfiguration, {
                  allHeaders: _ctx.headers,
                  modelValue: _ctx.selectedHeaders,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedHeaders) = $event)),
                  tableKey: "customersTableColumns"
                }, null, 8, ["allHeaders", "modelValue"]),
                _createVNode(_component_v_tooltip, {
                  location: "bottom",
                  disabled: !_ctx.searchTerm
                }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("span", _mergeProps({ class: "align-self-end ml-2 p-relative" }, props), [
                      _createVNode(_component_v_btn, {
                        size: "x-small",
                        variant: "text",
                        icon: "",
                        class: "align-self-end",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showFilter = !_ctx.showFilter)),
                        color: _ctx.showFilter ? 'primary' : undefined,
                        disabled: _ctx.loading || Boolean(_ctx.searchTerm),
                        title: "Filters"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.searchTerm)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 0,
                                size: "24"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("mdi-filter-off")
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (!_ctx.searchTerm)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 1,
                                size: "24"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("mdi-filter-variant")
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.numberOfFilter && !_ctx.searchTerm)
                            ? (_openBlock(), _createBlock(_component_v_badge, {
                                key: 2,
                                color: "primary",
                                transition: "v-fade-transition",
                                dot: "",
                                bordered: "",
                                "offset-x": "-1",
                                "offset-y": "-10"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["color", "disabled"])
                    ], 16)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" Filters ignored during search ")
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _createVNode(_component_v_spacer, { class: "d-none d-sm-block" }),
              _createElementVNode("div", _hoisted_3, [
                (_ctx.allowAddCustomers)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      size: "small",
                      color: "primary",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.newCustomer()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" New customer ")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.isAdmin)
                  ? (_openBlock(), _createBlock(_component_v_menu, {
                      key: 1,
                      location: "bottom",
                      start: ""
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps({ icon: "" }, props, {
                          class: "ml-4",
                          ripple: false,
                          variant: "text",
                          density: "compact"
                        }), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-dots-vertical")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.exportCustomers('xls')))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, { class: "text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Export customers with last used device id (XLS)")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_list_item, {
                              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.exportCustomers('csv')))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, { class: "text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Export customers with last used device id (CSV)")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Filters, {
            show: _ctx.showFilter,
            filter: _ctx.filter,
            onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showFilter = false)),
            onUpdate: _ctx.updateFilter,
            disabled: _ctx.loading
          }, null, 8, ["show", "filter", "onUpdate", "disabled"]),
          _createVNode(_component_v_data_table_server, {
            density: "compact",
            "row-props": _ctx.rowClass,
            headers: _ctx.selectedHeaders,
            items: _ctx.items,
            "items-length": _ctx.total,
            loading: _ctx.loading && 'primary',
            "mobile-breakpoint": 0,
            "onClick:row": _cache[14] || (_cache[14] = (event, { item }) => _ctx.rowClick(item)),
            "onContextmenu:row": _ctx.openContenxMenu,
            class: "no-wrap-table-sm",
            "sort-by": _ctx.sortBy,
            "onUpdate:sortBy": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.sortBy) = $event)),
            "must-sort": true,
            hover: "",
            mobile: false
          }, {
            [`item.type`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.getCustomerTypeName(item.type)), 1)
            ]),
            [`item.email`]: _withCtx(({ item }) => [
              (item.email && item.email.length > 30)
                ? (_openBlock(), _createBlock(_component_v_tooltip, {
                    key: 0,
                    location: "bottom",
                    color: "secondary",
                    "max-width": "500"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("div", _mergeProps({ class: "no-wrap" }, props), _toDisplayString(item.email.substring(0, 30) + "..."), 17)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(item.email), 1)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.email), 1))
            ]),
            [`item.created`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.moment(item.createdDate).format("lll")), 1)
            ]),
            [`item.updated`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(item.updatedDate ? _ctx.moment(item.updatedDate).format("lll") : ""), 1)
            ]),
            [`item.countryIso2`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(item.countryIso2 ? `${_ctx.countryByCode(item.countryIso2)}` : ""), 1)
            ]),
            [`item.notes`]: _withCtx(({ item }) => [
              (item.notes && item.notes.length > 15)
                ? (_openBlock(), _createBlock(_component_v_tooltip, {
                    key: 0,
                    location: "bottom",
                    color: "secondary",
                    "max-width": "500"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("div", _mergeProps({ class: "no-wrap" }, props), _toDisplayString(item.notes.substring(0, 15) + "..."), 17)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(item.notes), 1)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.notes), 1))
            ]),
            bottom: _withCtx(() => [
              _createVNode(_component_DataTableFooter, {
                page: _ctx.page,
                "onUpdate:page": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.page) = $event)),
                items: _ctx.items,
                itemsLength: _ctx.total,
                itemsPerPage: _ctx.itemsPerPage,
                "onUpdate:itemsPerPage": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
                itemsPerPageOptions: [15, 25, 50]
              }, null, 8, ["page", "items", "itemsLength", "itemsPerPage"])
            ]),
            _: 2
          }, 1032, ["row-props", "headers", "items", "items-length", "loading", "onContextmenu:row", "sort-by"]),
          _createVNode(_component_v_overlay, {
            position: "absolute",
            "model-value": _ctx.loading,
            opacity: "0",
            persistent: ""
          }, null, 8, ["model-value"])
        ]),
        _: 1
      }),
      _createVNode(_component_EditCustomer, {
        modelValue: _ctx.customerToEdit,
        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.customerToEdit) = $event)),
        onUpdated: _ctx.reload,
        initData: _ctx.initData,
        customerInitTab: _ctx.customerInitTab
      }, null, 8, ["modelValue", "onUpdated", "initData", "customerInitTab"]),
      _createVNode(_component_DataTableContextMenu, {
        modelValue: _ctx.contextMenuEventItem,
        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.contextMenuEventItem) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}