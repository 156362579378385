export enum UserPermissionType {
    ViewCustomers = 100,
    AddCustomers = 101,
    EditCustomers = 102,
    DeleteCustomers = 103,

    ViewDevices = 200,
    //AddDevices = 201,
    EditDevices = 202,
    DeleteDevices = 203,
    ViewDeviceLocation = 204,
    ViewDeviceLogs = 205,
    EditAllowUpdateProperty = 206,
    ViewFirmwareUpdateUrl = 290,

    ViewPoi = 300,
    AddPoi = 301,
    EditPoi = 302,
    DeletePoi = 303,

    ViewAds = 400,
    AddAds = 401,
    EditAds = 402,
    DeleteAds = 403,
    EditAdsSettings = 410,

    ViewIssues = 500,
    AddIssues = 501,
    EditIssues = 502,
    DeleteIssues = 503,
    ManageIssueDevices = 510
}
