import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4539781f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "api-key-resp-status-chart-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "api-key-resp-status-chart"
}
const _hoisted_3 = {
  key: 1,
  class: "text-center text-body-2 text--disabled mt-2"
}
const _hoisted_4 = { class: "api-key-chart-total text-caption mt-1" }
const _hoisted_5 = { class: "font-weight-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_chart = _resolveComponent("v-chart")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showChart)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_v_overlay, {
                key: 0,
                position: "absolute",
                class: "progress",
                opacity: "0",
                "z-index": "101"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_progress_linear, {
                    indeterminate: "",
                    position: "absolute"
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(), _createBlock(_component_v_chart, {
            key: _ctx.option.series && _ctx.option.series.length,
            ref: "chart",
            option: _ctx.option,
            autoresize: "",
            class: _normalizeClass({ 'opacity-50': _ctx.loading })
          }, null, 8, ["option", "class"]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "No data available")),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.total, ({ name, value }, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "mx-3 text-no-wrap"
        }, [
          _createVNode(_component_v_icon, {
            size: "small",
            class: "mb-1",
            color: _ctx.getIconByStatus(name).color
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getIconByStatus(name).icon), 1)
            ]),
            _: 2
          }, 1032, ["color"]),
          _createTextVNode(" " + _toDisplayString(`${name}: `) + " ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(value), 1)
        ]))
      }), 128))
    ])
  ]))
}