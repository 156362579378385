<template>
  <div class="issue-current-devices-container">
    <div class="text-subtitle-2">Affected devices</div>
    <div class="d-flex flex-grow-1">
      <v-text-field
        v-model="searchTerm"
        append-inner-icon="mdi-magnify"
        color="primary"
        variant="underlined"
        label="Search"
        hide-details
        clearable
        @click:clear="search()"
        v-on:input="search()"
        v-on:keypress.enter="search(true)"
      ></v-text-field>
      <v-btn
        size="x-small"
        variant="text"
        icon
        class="align-self-end ml-4"
        @click="reload"
        :disabled="loading"
        title="Refresh"
      >
        <v-icon size="24">mdi-reload</v-icon>
      </v-btn>
    </div>
    <div class="mt-2 mb-3">
      <v-chip
        class="mr-2"
        v-for="item in issueDeviceStatuses"
        :key="item.value"
        :variant="!activeFilters.includes(item.value) ? 'outlined' : 'flat'"
        :color="IssueHelper.getDeviceStatusColor(item.value, true)"
        :text-color="$vuetify.theme.dark || activeFilters.includes(item.value) ? 'white' : 'secondary-darken3'"
        pill
        @click="changeFilter(item.value)"
        size="small"
      >
        {{ `${item.title}: ${getStatusCount(item.value)}` }}
      </v-chip>
    </div>
    <v-data-table-server
      class="device-table"
      item-key="deviceId"
      density="compact"
      :items-length="total"
      :headers="headers"
      :items="items"
      v-model:sort-by="sortBy"
      v-model:page="page"
      :must-sort="true"
      hover
      :mobile="false"
      :loading="loading"
      :mobile-breakpoint="0"
    >
      <template v-slot:[`item.deviceId`]="{ item }">
        <div class="no-wrap">
          {{ item.device.deviceId }}
          <DeviceMenu v-model="item.device.deviceId" size="small" />
        </div>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ getDeviceTypeName(item.device.type) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-select
          v-model="item.status"
          :items="issueDeviceStatuses"
          density="compact"
          flat
          background-color="transparent"
          hide-details
          class="status-select"
          :readonly="!canManageIssueDevices && !value?.isArchived"
          :disabled="!canManageIssueDevices && !value?.isArchived"
          @update:modelValue="(newStatus) => setDeviceStatus(newStatus, item)"
          variant="solo"
        >
          <template v-slot:selection="{ item }">
            <span class="device-status" :class="item.raw.textColor">{{ item.title }}</span>
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item density="compact" v-bind="props" class="text-body-2" color="red" :class="item.raw.textColor">
            </v-list-item>
          </template>
        </v-select>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mr-2"
          v-if="canViewDevices"
          @click="deviceToEdit = { ...item.device }"
          icon
          title="Open"
          density="compact"
          variant="text"
          size="small"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn
          v-if="value && !value.isArchived && canManageIssueDevices"
          :loading="deletingDeviceId.includes(item.device.deviceId)"
          @click="removeDeviceConfirm(item.device)"
          icon
          density="compact"
          variant="text"
          title="Remove from affected devices"
          size="small"
        >
          <v-icon> mdi-close-thick </v-icon>
        </v-btn>
      </template>
      <template v-slot:bottom>
        <DataTableFooter
          v-model:page="page"
          :items="items"
          :itemsLength="total"
          v-model:itemsPerPage="itemsPerPage"
          :itemsPerPageOptions="[15, 25, 50]"
        />
      </template>
    </v-data-table-server>
    <v-overlay position="absolute" :model-value="loading" opacity="0" />
    <EditDevice v-model="deviceToEdit" v-on:updated="reload" :hasUrlUpdate="false" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue, toNative, Model, Setup } from "vue-facing-decorator";
import DeviceHelper from "@/helpers/deviceHelper";
import IssueDeviceListItem from "@/types/IssueDeviceListItem";
import { UserPermissionType } from "@/types/UserPermissionType";
import Issue from "@/types/Issue";
import { DeviceType } from "@/types/DeviceType";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import Device from "@/types/Device";
import userProfileService from "@/services/UserProfileService";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import EditDevice from "@/components/devices/EditDevice.vue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";
//@ts-ignore
import DataTableFooter from "@/components/common/DataTableFooter.vue";

@Component({
  components: { DeviceMenu, EditDevice, DataTableFooter },
  emits: ["remove", "changeStatus"],
})
export default class IssueCurrentDevices extends Vue {
  @Prop({ default: [] })
  readonly statusSummary!: { status: IssueDeviceStatus; count: number }[];

  @Model()
  readonly value!: Issue;

  @Prop()
  readonly forceReload!: object;

  total = 0;
  loading = false;
  deletingDeviceId: number[] = [];

  itemsPerPage = 15;
  page = 1;
  sortBy: { key: string; order: boolean | "asc" | "desc" }[] = [];

  searchTerm = "";
  items: IssueDeviceListItem[] = [];
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  ignoreOptionsChange: boolean = false;
  deviceToEdit: Device | null = null;
  cancelDeviceStatusToken: CancelTokenSource | undefined = undefined;
  IssueHelper = IssueHelper;

  @Watch("itemsPerPage")
  @Watch("sortBy")
  @Watch("page")
  onPropertyChanged() {
    if (!this.ignoreOptionsChange) {
      this.getIssueDevices();
    }
  }

  @Watch("forceReload")
  onForceReload() {
    this.getIssueDevices(true);
  }

  mounted() {
    this.getIssueDevices();
  }

  get canEditIssues() {
    return !this.value?.isArchived && userProfileService.hasPermission(UserPermissionType.EditIssues);
  }

  get canViewDevices() {
    return userProfileService.hasPermission(UserPermissionType.ViewDevices);
  }

  get canManageIssueDevices() {
    return !this.value?.isArchived && (userProfileService.hasPermission(UserPermissionType.EditIssues) || userProfileService.hasPermission(UserPermissionType.ManageIssueDevices));
  }

  get headers() {
    const mainHeaders: any = [
      { title: "ID", align: "start", key: "deviceId", sortable: true },
      { title: "Name", key: "device.deviceName", sortable: false },
      { title: "Type", key: "type", sortable: false },
      { title: "Status", key: "status", sortable: true },
    ];
    if (this.canManageIssueDevices || this.canViewDevices) {
      mainHeaders.push({ text: "", value: "actions", align: "end", cellProps: { class: "actions" }, sortable: false });
    }
    return mainHeaders;
  }

  issueDeviceStatuses = [
    {
      title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.New),
      value: IssueDeviceStatus.New,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.New),
    },
    {
      title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Active),
      value: IssueDeviceStatus.Active,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Active),
    },
    {
      title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Resolved),
      value: IssueDeviceStatus.Resolved,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Resolved),
    },
  ];

  activeFilters: IssueDeviceStatus[] = [];
  getDeviceTypeName(type: DeviceType) {
    return DeviceHelper.getDeviceTypeDisplayName(type);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getIssueDevices(true);
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getIssueDevices(true);
      }, 1000);
    }
  }

  changeFilter(status: IssueDeviceStatus) {
    const ind = this.activeFilters.findIndex((value) => value === status);
    if (ind !== -1) {
      this.activeFilters.splice(ind, 1);
    }
    if (ind === -1) {
      this.activeFilters = [status];
    }
    this.reload();
  }

  reload() {
    this.getIssueDevices();
  }

  getIssueDevices(resetPagination: boolean = false) {
    if (!this.value) {
      return;
    }
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Reset pagination
    if (resetPagination) {
      this.ignoreOptionsChange = true;
      this.page = 1;
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      if (!this.value) {
        return;
      }

      const orderDesc =
        typeof this.sortBy[0]?.order === "boolean" ? this.sortBy[0]?.order : this.sortBy[0]?.order.toString() === "desc";
      issueResource
        .getDevicesByIssuePaged(
          this.value.issueId,
          this.itemsPerPage,
          this.page,
          this.searchTerm,
          this.sortBy[0]?.key,
          orderDesc,
          this.activeFilters,
          this.cancelToken
        )
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
        })
        .catch(issueResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
          this.ignoreOptionsChange = false;
        });
    }, 10);
  }

  removeDeviceConfirm(device: Device) {
    if ((!this.canEditIssues && !this.canManageIssueDevices) || !device) {
      return;
    }

    this.$confirm
      .show(`Remove device ID ${device.deviceId} from affected devices?`, {
        width: 360,
      })
      .then((confirmed) => {
        if (confirmed) {
          this.removeDevice(device);
        }
      });
  }

  removeDevice(device: Device) {
    if ((!this.canEditIssues && !this.canManageIssueDevices) || this.value == null) {
      return;
    }

    const deviceId = device.deviceId;
    this.deletingDeviceId.push(deviceId);
    issueResource
      .removeDevice(this.value.issueId, deviceId)
      .then(() => {
        this.items = this.items.filter((v) => v.device.deviceId !== deviceId);
        this.$emit("remove");
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.deletingDeviceId = this.deletingDeviceId.filter((v) => v !== deviceId);
      });
  }

  setDeviceStatus(status: IssueDeviceStatus, deviceItem: IssueDeviceListItem) {
    const previousStatus = deviceItem.status;
    // Cancel existing request
    if (this.cancelDeviceStatusToken) {
      this.cancelDeviceStatusToken.cancel();
    }

    if (!this.canManageIssueDevices || !deviceItem) {
      return;
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.cancelDeviceStatusToken = axios.CancelToken.source();
      issueResource
        .updateIssueDeviceStatus(this.value.issueId, deviceItem.device.deviceId, status, this.cancelDeviceStatusToken)
        .then(() => {
          this.$emit("changeStatus");
        })
        .catch((e) => {
          issueResource.defaultErrorHandler(e);
          this.items = this.items.map((item) => {
            if (item.device.deviceId === deviceItem.device.deviceId) {
              return { ...item, status: previousStatus };
            }
            return item;
          });
        })
        .finally(() => {
          this.cancelDeviceStatusToken = undefined;
        });
    }, 10);
  }

  getStatusCount(status: IssueDeviceStatus) {
    return this.statusSummary.find((v) => v.status === status)?.count || 0;
  }
}
</script>

<style scoped>
.status-select {
  width: 90px;
}

.device-status {
  font-size: 14px;
}

.issue-current-devices-container :deep(.actions) {
  white-space: nowrap;
}

.status-select :deep(.v-input__control) > .v-field--active .v-field__input {
  padding: 0 !important;
  padding-inline-end: 0;
}

.status-select :deep(.v-input__control) > .v-field--active {
  padding: 0 !important;
  padding-inline-end: 0;
}

.device-table :deep(.v-data-table-header th) {
  white-space: nowrap;
}
</style>
