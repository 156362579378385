import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-grow-1" }
const _hoisted_2 = { class: "text-right align-self-end mt-2 mt-sm-0" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_EditAPIKey = _resolveComponent("EditAPIKey")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-block d-sm-flex" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.searchTerm,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
                  "append-inner-icon": "mdi-magnify",
                  variant: "underlined",
                  color: "primary",
                  label: "Search",
                  "hide-details": "",
                  clearable: ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_btn, {
                  size: "x-small",
                  variant: "text",
                  icon: "",
                  class: "align-self-end ml-4",
                  onClick: _ctx.reload,
                  disabled: _ctx.loading,
                  title: "Refresh"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { size: "24" }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-reload")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ]),
              _createVNode(_component_v_spacer, { class: "d-none d-sm-block" }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_btn, {
                  size: "small",
                  color: "primary",
                  class: "align-self-end",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newApiKey())),
                  "test-id": "newApiKey"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" New API key ")
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_data_table, {
            density: "compact",
            headers: _ctx.headers,
            items: _ctx.items,
            "row-props": _ctx.rowClass,
            options: _ctx.options,
            loading: _ctx.loading,
            search: _ctx.searchTerm,
            "disable-pagination": true,
            "hide-default-footer": true,
            "items-per-page": "-1",
            "mobile-breakpoint": 0,
            "onClick:row": _cache[2] || (_cache[2] = (event, { item }) => _ctx.rowClick(item)),
            "test-id": "api-keys-table",
            "must-sort": true,
            hover: "",
            mobile: false
          }, {
            [`item.createdAt`]: _withCtx(({ item }) => [
              (item.createdAt)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(`${_ctx.moment(item.createdAt).format("lll")} (${item.createdBy})`), 1))
                : _createCommentVNode("", true)
            ]),
            [`item.lastModifiedAt`]: _withCtx(({ item }) => [
              (item.lastModifiedAt)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(`${_ctx.moment(item.lastModifiedAt).format("lll")} (${item.lastModifiedBy})`), 1))
                : _createCommentVNode("", true)
            ]),
            bottom: _withCtx(() => []),
            _: 2
          }, 1032, ["headers", "items", "row-props", "options", "loading", "search"]),
          _createVNode(_component_v_overlay, {
            position: "absolute",
            "model-value": _ctx.loading,
            opacity: "0"
          }, null, 8, ["model-value"])
        ]),
        _: 1
      }),
      _createVNode(_component_EditAPIKey, {
        modelValue: _ctx.apiKeyToEdit,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.apiKeyToEdit) = $event)),
        onUpdated: _ctx.reload,
        initTab: _ctx.editInitTab
      }, null, 8, ["modelValue", "onUpdated", "initTab"])
    ]),
    _: 1
  }))
}