<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="text-right align-self-end mt-0 d-block d-sm-none">
          <CreateIssue />
        </div>
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            hide-details
            clearable
            @click:clear="search()"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
            variant="underlined"
            color="primary"
          ></v-text-field>

          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>

          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="issuesTableColumns" />

          <v-btn
            variant="text"
            size="x-small"
            icon
            class="align-self-end"
            @click="showFilter = !showFilter"
            :color="showFilter ? 'primary' : undefined"
            :disabled="loading"
            title="Filters"
          >
            <v-icon size="24">mdi-filter-variant</v-icon>
            <v-badge
              v-if="numberOfFilter"
              transition="v-fade-transition"
              dot
              bordered
              offset-x="-1"
              offset-y="-10"
              color="primary"
            />
          </v-btn>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 d-none d-sm-block">
          <CreateIssue />
        </div>
      </v-card-title>

      <Filters :show="showFilter" :filter="filter" @close="showFilter = false" @update="updateFilter" :disabled="loading" />

      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
        :loading="loading"
        :mobile-breakpoint="0"
        @click:row="(event, { item }) => rowClick(item)"
      >
        <template v-slot:[`item.status`]="{ item }">
          <span :class="IssueHelper.getIssueStatusColor(item.status, true)">{{ getIssueStatusName(item.status) }}</span>
        </template>

        <template v-slot:[`item.priority`]="{ item }">
          <v-icon
            :color="IssueHelper.getIssuePriorityColor(item.priority)"
            :title="IssueHelper.getIssuePriorityName(item.priority)"
          >
            {{ IssueHelper.getIssuePriorityIcon(item.priority) }}
          </v-icon>
        </template>

        <template v-slot:[`item.tags`]="{ item }">
          <v-chip size="small" class="ma-1px" v-for="tag in item.tags" v-bind:key="tag.tagId">
            {{ tag.name }}
          </v-chip>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <span v-if="item.createdAt">{{ moment(item.createdAt).format("lll") }}</span>
        </template>
        <template v-slot:[`item.lastModifiedAt`]="{ item }">
          <span v-if="item.lastModifiedAt">{{ moment(item.lastModifiedAt).format("lll") }}</span>
        </template>

        <template v-slot:[`item.isArchived`]="{ item }">
          <div class="ml-2">
            <v-icon size="small" color="green" v-if="item.isArchived">mdi-check</v-icon>
          </div>
        </template>
        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay contained :model-value="loading" opacity="0" />
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, toNative } from "vue-facing-decorator";
import Issue from "@/types/Issue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import Filters from "@/components/common/Filters/Filters.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import CreateIssue from "@/components/issues/CreateIssue.vue";
import userStorage from "@/services/UserStorageService";
import IssueHelper from "@/helpers/issueHelper";
import TableFilter from "@/types/TableFilter";
import { ArchivedIssueFilter } from "@/types/ArchivedIssueFilter";
import { IssueStatus } from "@/types/IssueStatus";
import tagResource from "@/resources/TagResource";
import { TagType } from "@/types/TagType";
//@ts-ignore
import DataTableFooter from "@/components/common/DataTableFooter.vue";

@Component({
  name: "Issues", // name is needed for keep-alive
  components: { CreateIssue, Filters, TableConfiguration, DataTableFooter },
})
class Issues extends Vue {
  @Prop({ default: null })
  readonly initFilter!: { [key: string]: TableFilter["selected"] };

  moment = moment;
  IssueHelper = IssueHelper;
  showFilter = false;
  total = 0;
  items: Issue[] = [];
  loading = false;

  optionsStorageKey = "issuesTable";
  itemsPerPage = userStorage.get(this.optionsStorageKey)?.itemsPerPage ?? 15;

  sortBy: { key: string; order: boolean | "asc" | "desc" }[] = userStorage.get(this.optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(this.optionsStorageKey)?.sortBy
    : [{ key: "issueId", order: true }];

  page = userStorage.get(this.optionsStorageKey)?.page ?? 1;

  searchTermStorageKey = "issuesTableSearchTerm";
  searchTerm = userStorage.get(this.searchTermStorageKey) ?? "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  issueToEdit: Issue | null = null;

  mounted() {
    this.getData();
  }

  @Watch("itemsPerPage")
  @Watch("sortBy", { deep: true })
  @Watch("page")
  onPropertyChanged() {
    this.getData();
  }

  selectedHeaders = [];
  headers = [
    { title: "ID", align: "start", key: "issueId" },
    { title: "Status", key: "status" },
    { title: "Priority", key: "priority" },
    { title: "Title", key: "name" },
    { title: "Tags", key: "tags" },
    { title: "Affected devices", key: "numberOfAffectedDevices", sortable: false },
    { title: "Comments", key: "numberOfComments", sortable: false },
    { title: "Created", key: "createdAt" },
    { title: "Reporter", key: "createdBy" },
    { title: "Updated", key: "lastModifiedAt" },
    { title: "Updated by", key: "lastModifiedBy" },
    { title: "Archived", key: "isArchived" },
  ];

  created() {
    this.showFilter = Boolean(Object.values(this.initFilter || {}).length);
    this.filter = [
      {
        title: "Status",
        icon: "mdi-checkbox-multiple-marked",
        filterName: "status",
        searchable: false,
        selected: this.initFilter.status || [],
        itemsCallback: (search?: string) => {
          if (!search) return IssueHelper.getIssueFilter();
          return IssueHelper.getIssueFilter().filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
        },
      },

      {
        title: "Tags",
        icon: "mdi-tag-multiple",
        filterName: "tags",
        searchable: true,
        selected: this.initFilter.tags || [],
        itemsCallback: this.getTags,
      },

      {
        title: "Reporters",
        icon: "mdi-account-multiple",
        filterName: "reporters",
        searchable: true,
        selected: this.initFilter.reporters || [],
        itemsCallback: this.getReporters,
      },
      {
        title: "Archived issues",
        icon: "mdi-archive-outline",
        filterName: "archivedIssues",
        searchable: false,
        selected: this.initFilter?.archivedIssues || [],
        disableMultiple: true,
        itemsCallback: (search?: string) => {
          const data = [
            { text: "Include archived issues", value: ArchivedIssueFilter.includeArchivedIssue },
            { text: "Only archived issues", value: ArchivedIssueFilter.onlyArchivedIssue },
          ];
          if (!search) return data;
          return data.filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
        },
      },
    ];
  }
  filter: TableFilter[] = [];

  get numberOfFilter() {
    return Object.values(this.filter).reduce((acc, { selected }) => (acc += selected.length), 0);
  }

  updateFilter({ newFilter, eventOptions }: { newFilter: TableFilter[]; eventOptions: any }) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    this.searchThrottleTimer = setTimeout(
      () => {
        this.filter = newFilter;
        if (this.page > 1) {
          this.page = 1;
          return;
        }
        this.getData();
      },
      eventOptions.byRemoveBtn ? 0 : 1000
    );
  }

  getIssueStatusName(status: number) {
    return IssueHelper.getIssueStatusDisplayName(status);
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Save sorting, filters and search terms
    userStorage.set(this.optionsStorageKey, {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      sortBy: this.sortBy,
    });
    userStorage.set(this.searchTermStorageKey, this.searchTerm);

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      const orderDesc =
        typeof this.sortBy[0].order === "boolean" ? this.sortBy[0].order : this.sortBy[0].order.toString() === "desc";

      issueResource
        .getIssuesPaged(
          this.itemsPerPage,
          this.page,
          this.searchTerm,
          this.sortBy[0].key,
          orderDesc,
          this.filter.find(({ filterName }) => filterName === "status")?.selected.map(({ value }) => value),
          this.filter.find(({ filterName }) => filterName === "reporters")?.selected.map(({ value }) => value),
          this.filter.find(({ filterName }) => filterName === "tags")?.selected.map(({ value }) => value),
          this.filter.find(({ filterName }) => filterName === "archivedIssues")?.selected.map(({ value }) => value)[0],
          undefined,
          this.cancelToken
        )
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
          if (resp.data.totalPages < this.page) this.page = 1;
        })
        .catch(issueResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  getReporters(search?: string, cancelToken?: CancelTokenSource) {
    return issueResource
      .getReporters(search?.trim(), 5, cancelToken)
      .then((resp) => resp.data.map((user) => ({ text: user.username, value: user.userId })))
      .catch(issueResource.defaultErrorHandler);
  }

  getTags(search?: string, cancelToken?: CancelTokenSource) {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    return tagResource
      .getTagsPaged(5, 1, search?.trim(), [TagType.Issue], "issue_count", true, cancelToken)
      .then((resp) => resp.data.items.map((tag) => ({ text: tag.name, value: tag.tagId })))
      .catch(tagResource.defaultErrorHandler);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData();
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData();
      }, 1000);
    }
  }

  reload() {
    this.getData();
  }

  rowClick(item: Issue) {
    this.$router.push(`/support/issues/${item.issueId}`);
  }

  rowClass({ item }: { item: Issue }) {
    return {
      class: { "cursor-default": true, "text-grey": item.status === IssueStatus.Closed || item.isArchived === true },
    };
  }
}
export default toNative(Issues);
</script>

<style scoped></style>
