<template>
  <div>
    <div class="d-block mt-4">
      <v-select
        attach
        v-model="period"
        :items="periodOptions"
        variant="outlined"
        density="compact"
        class="flex-grow-0"
        style="min-width: 150px"
        hide-details
        label="Time window"
      />
    </div>
    <div class="d-block mt-2 pt-4">
      <div>API endpoint response status code</div>
      <ApiKeyRespStatusChart v-model="value" :period="period" />
    </div>
    <div class="mt-5">
      <div class="mb-3">Top API endpoint calls</div>
      <v-data-table
        density="compact"
        :headers="headers"
        :items="topApiEndpointItems"
        :loading="topEndpointLoading"
        :disable-pagination="true"
        :hide-default-footer="true"
        items-per-page="-1"
        :mobile-breakpoint="0"
      >
      <template #bottom></template>
      </v-data-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch , toNative , Model  } from "vue-facing-decorator";
import ApiKeyRespStatusChart from "@/components/apiKeys/ApiKeyRespStatusChart.vue";
import ApiKey from "@/types/ApiKey";
import TopApiKeyEndpointsUsage from "@/types/TopApiKeyEndpointsUsage";
import axios, { CancelTokenSource } from "axios";
import apiKeysResource from "@/resources/ApiKeysResource";

@Component({
  components: { ApiKeyRespStatusChart },
})
export default class ApiKeyStats extends Vue {
  topEndpointCancelToken: CancelTokenSource | undefined = undefined;
  topEndpointLoading = false;
  topApiEndpointItems: TopApiKeyEndpointsUsage[] = []
  period = 30;
  
  periodOptions = [
    { title: "Last 7 days", value: 7 },
    { title: "Last 30 days", value: 30 },
    { title: "Last 3 months", value: 90 },
    { title: "Last year", value: 365 },
  ];

  headers = [
    { title: "Endpoint", align: "start", key: "endpoint" },
    { title: "Number of calls",  align: "end", key: "count" },
  ];
  
  @Model({ default: null })
  readonly value!: ApiKey | null;

  @Watch("period")
  onChangePeriod() {
    if (this.period) this.getTopEndpointsUsage();
  }

  mounted() {
    this.getTopEndpointsUsage();
  }

  getTopEndpointsUsage() {
    // Cancel existing request
    if (this.topEndpointCancelToken) {
      this.topEndpointCancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.topEndpointLoading = true;
      this.topEndpointCancelToken = axios.CancelToken.source();

      if (!this.value) return;

      apiKeysResource
        .getTopEndpointsUsage(this.value.apiKeyId, this.period, this.topEndpointCancelToken)
        .then((resp) => (this.topApiEndpointItems = resp.data))
        .catch(apiKeysResource.defaultErrorHandler)
        .finally(() => {
          this.topEndpointLoading = false;
          this.topEndpointCancelToken = undefined;
        });
    }, 10);
  }
}
</script>
