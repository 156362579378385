<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            append-inner-icon="mdi-magnify"
            color="primary"
            variant="underlined"
            label="Search"
            hide-details
            clearable
     
            test-id="search"
          ></v-text-field>
          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="userGroupsTableColumns" />
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn size="small" color="primary" class="align-self-end" @click="newUserGroup()" test-id="new-user-group">
            New user group
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        density="compact"
        :headers="selectedHeaders"
        :items="items"
        :row-props="rowClass"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
        :loading="loading"
        :search="searchTerm"
        :disable-pagination="true"
        :hide-default-footer="true"
        items-per-page="-1"
        :mobile-breakpoint="0"
        @click:row="(event, { item }) => rowClick(item)"
      >
        <template #bottom></template>
      </v-data-table>

      <v-overlay position="absolute" :model-value="loading" opacity="0" />
    </v-card>

    <EditUserGroup v-model="userGroupToEdit" v-on:updated="reload" />
  </v-container>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop, toNative } from "vue-facing-decorator";
import userGroupResource from "@/resources/UserGroupResource";
import axios, { CancelTokenSource } from "axios";
import UserGroup from "@/types/UserGroup";
import EditUserGroup from "@/components/users/EditUserGroup.vue";
import userStorage from "@/services/UserStorageService";
import TableConfiguration from "@/components/common/TableConfiguration.vue";

@Component({
  components: {
    EditUserGroup,
    TableConfiguration,
  },
})
class UserGroups extends Vue {
  @Prop({ default: null })
  initData!: { userGroupId: number } | null;

  items: UserGroup[] = [];
  loading = false;
  cancelToken: CancelTokenSource | undefined = undefined;

  searchTerm: string = "";

  optionsStorageKey = "userGroupsTable";

  sortBy: { key: string; order: boolean | "asc" | "desc" }[] = userStorage.get(this.optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(this.optionsStorageKey)?.sortBy
    : [{ key: "userGroupId", order: true }];

  @Watch("sortBy", { deep: true })
  onPropertyChanged() {
    userStorage.set(this.optionsStorageKey, { sortBy: this.sortBy });
  }

  selectedHeaders = [];
  headers = [
    { title: "ID", align: "start", key: "userGroupId" },
    { title: "Name", key: "name" },
  ];

  mounted() {
    if (this.initData?.userGroupId) {
      this.getInitUserGroupById(this.initData?.userGroupId);
    }
  }

  getInitUserGroupById(userGroupId: number) {
    userGroupResource
      .getUserGroupById(userGroupId)
      .then((resp) => {
        this.rowClick(resp.data);
      })
      .catch(userGroupResource.defaultErrorHandler);
  }

  userGroupToEdit: UserGroup | null = null;

  created() {
    this.getData();
  }

  newUserGroup() {
    this.userGroupToEdit = {
      userGroupId: 0,
      name: "",
      userGroupPermissions: [],
    } as UserGroup;
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      userGroupResource
        .getAllUserGroups(this.cancelToken)
        .then((resp) => {
          this.items = resp.data;
        })
        .catch(userGroupResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  reload() {
    this.getData();
  }

  rowClick(item: UserGroup) {
    this.userGroupToEdit = Object.assign({}, item);
  }

  rowClass({ item }: { item: UserGroup }) {
    return { class: { "cursor-default": true } };
  }
}
export default toNative(UserGroups);
</script>
