import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "d-flex flex-grow-1" }
const _hoisted_2 = { class: "text-right align-self-end mt-2 mt-sm-0" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_TableConfiguration = _resolveComponent("TableConfiguration")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_DataTableFooter = _resolveComponent("DataTableFooter")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_EditUser = _resolveComponent("EditUser")!
  const _component_DataTableContextMenu = _resolveComponent("DataTableContextMenu")!
  const _component_EditUserGroup = _resolveComponent("EditUserGroup")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-block d-sm-flex" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.searchTerm,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
                  "append-inner-icon": "mdi-magnify",
                  color: "primary",
                  variant: "underlined",
                  label: "Search",
                  "hide-details": "",
                  clearable: "",
                  "onClick:clear": _cache[1] || (_cache[1] = ($event: any) => (_ctx.search())),
                  style: {},
                  onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.search())),
                  onKeypress: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.search(true)), ["enter"])),
                  "test-id": "search"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_btn, {
                  size: "x-small",
                  variant: "text",
                  icon: "",
                  class: "align-self-end ml-4",
                  onClick: _ctx.reload,
                  disabled: _ctx.loading,
                  title: "Refresh"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { size: "24" }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-reload")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"]),
                _createVNode(_component_TableConfiguration, {
                  allHeaders: _ctx.headers,
                  modelValue: _ctx.selectedHeaders,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedHeaders) = $event)),
                  tableKey: "usersTableColumns"
                }, null, 8, ["allHeaders", "modelValue"])
              ]),
              _createVNode(_component_v_spacer, { class: "d-none d-sm-block" }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_btn, {
                  size: "small",
                  color: "primary",
                  class: "align-self-end",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.newUser())),
                  "test-id": "new-user"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" New user ")
                  ]),
                  _: 1
                }),
                (_ctx.isAdmin)
                  ? (_openBlock(), _createBlock(_component_v_menu, {
                      key: 0,
                      location: "bottom left"
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps({ icon: "" }, props, {
                          density: "compact",
                          variant: "text",
                          class: "ml-4"
                        }), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-dots-vertical")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.exportUserActivity(_ctx.moment().subtract(1, 'year').year())))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(`Export user activity - ${_ctx.moment().subtract(1, "year").year()}`), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_list_item, {
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.exportUserActivity(_ctx.moment().year())))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(`Export user activity - ${_ctx.moment().year()}`), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_data_table_server, {
            density: "compact",
            "row-props": _ctx.rowClass,
            headers: _ctx.selectedHeaders,
            items: _ctx.items,
            "items-length": _ctx.total,
            "sort-by": _ctx.sortBy,
            "onUpdate:sortBy": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.sortBy) = $event)),
            "must-sort": true,
            hover: "",
            mobile: false,
            loading: _ctx.loading,
            "mobile-breakpoint": 0,
            "onClick:row": _cache[11] || (_cache[11] = (event, { item }) => _ctx.rowClick(item)),
            "onContextmenu:row": _ctx.openContenxMenu
          }, {
            [`item.isActive`]: _withCtx(({ item }) => [
              (item.isActive)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    size: "small",
                    color: "green"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-check")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            [`item.isAdministrator`]: _withCtx(({ item }) => [
              (item.isAdministrator)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    size: "small",
                    color: "green"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-check")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            [`item.groups`]: _withCtx(({ item }) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.userGroups, (group) => {
                return (_openBlock(), _createBlock(_component_v_chip, {
                  size: "small",
                  class: "ma-1px cursor-pointer",
                  key: group.userGroupId,
                  onClick: _withModifiers(($event: any) => (_ctx.userGroupToEdit = JSON.parse(JSON.stringify(group))), ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(group.name), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            [`item.lastLoginDate`]: _withCtx(({ item }) => [
              (item.lastLoginDate)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.moment(item.lastLoginDate).format("lll")), 1))
                : _createCommentVNode("", true)
            ]),
            [`item.mfa`]: _withCtx(({ item }) => [
              (item.mfaType)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getMfaTypeName(item.mfaType)), 1))
                : _createCommentVNode("", true)
            ]),
            bottom: _withCtx(() => [
              _createVNode(_component_DataTableFooter, {
                page: _ctx.page,
                "onUpdate:page": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.page) = $event)),
                items: _ctx.items,
                itemsLength: _ctx.total,
                itemsPerPage: _ctx.itemsPerPage,
                "onUpdate:itemsPerPage": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
                itemsPerPageOptions: [15, 25, 50]
              }, null, 8, ["page", "items", "itemsLength", "itemsPerPage"])
            ]),
            _: 2
          }, 1032, ["row-props", "headers", "items", "items-length", "sort-by", "loading", "onContextmenu:row"]),
          _createVNode(_component_v_overlay, {
            position: "absolute",
            "model-value": _ctx.loading,
            opacity: "0"
          }, null, 8, ["model-value"])
        ]),
        _: 1
      }),
      _createVNode(_component_EditUser, {
        modelValue: _ctx.userToEdit,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.userToEdit) = $event)),
        onUpdated: _ctx.reload,
        initTab: _ctx.userInitTab
      }, null, 8, ["modelValue", "onUpdated", "initTab"]),
      _createVNode(_component_DataTableContextMenu, {
        modelValue: _ctx.contextMenuEventItem,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.contextMenuEventItem) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_EditUserGroup, {
        modelValue: _ctx.userGroupToEdit,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.userGroupToEdit) = $event)),
        onUpdated: _ctx.reload,
        isDeleteBtn: false
      }, null, 8, ["modelValue", "onUpdated"])
    ]),
    _: 1
  }))
}