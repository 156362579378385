<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1 align-end">
          <div class="flex-grow-0 pr-2 select-auto-width">
            <v-select
              v-model="selectedSearchFields"
              :items="searchFields"
              label="Fields"
              hide-details
              @update:modelValue="searchTerm ? search(true) : false"
              variant="underlined"
              color="primary"
            ></v-select>
          </div>
          <v-text-field
            v-model="searchTerm"
            append-inner-icon="mdi-magnify"
            label="Search for"
            hide-details
            clearable
            prefix=":"
            style="max-width"
            @click:clear="search()"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
            variant="underlined"
            color="primary"
          ></v-text-field>
          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="customersTableColumns" />

          <v-tooltip location="bottom" :disabled="!searchTerm">
            <template v-slot:activator="{ props }">
              <span class="align-self-end ml-2 p-relative" v-bind="props">
                <v-btn
                  size="x-small"
                  variant="text"
                  icon
                  class="align-self-end"
                  @click="showFilter = !showFilter"
                  :color="showFilter ? 'primary' : undefined"
                  :disabled="loading || Boolean(searchTerm)"
                  title="Filters"
                >
                  <v-icon size="24" v-if="searchTerm">mdi-filter-off</v-icon>

                  <v-icon size="24" v-if="!searchTerm">mdi-filter-variant</v-icon>
                  <v-badge
                    color="primary"
                    v-if="numberOfFilter && !searchTerm"
                    transition="v-fade-transition"
                    dot
                    bordered
                    offset-x="-1"
                    offset-y="-10"
                  />
                </v-btn>
              </span>
            </template>
            Filters ignored during search
          </v-tooltip>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn v-if="allowAddCustomers" size="small" color="primary" @click="newCustomer()"> New customer </v-btn>

          <v-menu v-if="isAdmin" location="bottom" start>
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props" class="ml-4" :ripple="false" variant="text" density="compact">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="exportCustomers('xls')">
                <v-list-item-title class="text-wrap">Export customers with last used device id (XLS)</v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportCustomers('csv')">
                <v-list-item-title class="text-wrap">Export customers with last used device id (CSV)</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>
      <Filters :show="showFilter" :filter="filter" @close="showFilter = false" @update="updateFilter" :disabled="loading" />
      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total"
        :loading="loading && 'primary'"
        :mobile-breakpoint="0"
        @click:row="(event, { item }) => rowClick(item)"
        @contextmenu:row="openContenxMenu"
        class="no-wrap-table-sm"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
      >
        <template v-slot:[`item.type`]="{ item }">
          {{ getCustomerTypeName(item.type) }}
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <v-tooltip v-if="item.email && item.email.length > 30" location="bottom" color="secondary" max-width="500">
            <template v-slot:activator="{ props }">
              <div class="no-wrap" v-bind="props">{{ item.email.substring(0, 30) + "..." }}</div>
            </template>
            <span class="pre-wrap">{{ item.email }}</span>
          </v-tooltip>
          <span v-else>{{ item.email }}</span>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ moment(item.createdDate).format("lll") }}
        </template>
        <template v-slot:[`item.updated`]="{ item }">
          {{ item.updatedDate ? moment(item.updatedDate).format("lll") : "" }}
        </template>
        <template v-slot:[`item.countryIso2`]="{ item }">
          {{ item.countryIso2 ? `${countryByCode(item.countryIso2)}` : "" }}
        </template>
        <template v-slot:[`item.notes`]="{ item }">
          <v-tooltip v-if="item.notes && item.notes.length > 15" location="bottom" color="secondary" max-width="500">
            <template v-slot:activator="{ props }">
              <div class="no-wrap" v-bind="props">{{ item.notes.substring(0, 15) + "..." }}</div>
            </template>
            <span class="pre-wrap">{{ item.notes }}</span>
          </v-tooltip>
          <span v-else>{{ item.notes }}</span>
        </template>

        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay position="absolute" :model-value="loading" opacity="0" persistent />
    </v-card>

    <EditCustomer v-model="customerToEdit" v-on:updated="reload" :initData="initData" :customerInitTab="customerInitTab" />
    <DataTableContextMenu v-model="contextMenuEventItem" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, toNative } from "vue-facing-decorator";
import EditCustomer from "@/components/customers/EditCustomer.vue";
import Customer from "@/types/Customer";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import { CustomerType } from "@/types/CustomerType";
import customerResource from "@/resources/CustomerResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import CustomerHelper from "@/helpers/customerHelper";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import userStorage from "@/services/UserStorageService";
import commonHelper from "@/helpers/commonHelper";
import { nextTick } from "vue";
import TableFilter from "@/types/TableFilter";
import Filters from "@/components/common/Filters/Filters.vue";
//@ts-ignore
import DataTableFooter from "@/components/common/DataTableFooter.vue";

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

@Component({
  name: "Customers", // name is needed for keep-alive
  components: {
    EditCustomer,
    DataTableContextMenu,
    TableConfiguration,
    Filters,
    DataTableFooter,
  },
})
class Customers extends Vue {
  moment = moment;

  total = 0;
  items: Customer[] = [];
  loading = false;

  optionsStorageKey = "customersTable";

  itemsPerPage = userStorage.get(this.optionsStorageKey)?.itemsPerPage ?? 15;

  sortBy: { key: string; order: boolean | "asc" | "desc" }[] = userStorage.get(this.optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(this.optionsStorageKey)?.sortBy
    : [{ key: "customerId", order: true }];

  page = userStorage.get(this.optionsStorageKey)?.page ?? 1;

  searchTermStorageKey = "customersTableSearchTerm";
  searchTerm = userStorage.get(this.searchTermStorageKey) ?? "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;

  searchFieldsStorageKey = "customersSelectedSearchField";
  selectedSearchFields = userStorage.get(this.searchFieldsStorageKey) ?? "";
  searchFields = [
    { title: "*", value: "" },
    { title: "ID", value: "id" },
    { title: "Phone number", value: "phone" },
  ];

  @Prop({ default: null })
  readonly initFilter!: { [key: string]: TableFilter["selected"] };

  @Prop({ default: {} })
  initData!: { customerId: number; customerTab: string } | null;

  customerToEdit: Customer | null = null;
  customerInitTab: string | null = null;
  ignoreOptionsChange: boolean = false;

  created() {
    this.showFilter = Boolean(Object.values(this.initFilter || {}).length);
    this.filter = [
      {
        title: "Country",
        icon: "mdi-map-marker",
        filterName: "countryIso2",
        searchable: false,
        selected: this.initFilter?.countryIso2 || [],
        disableMultiple: true,
        dividerValues: ["DK"],
        itemsCallback: () => {
          const countryArr = Object.entries(countries.getNames("en", { select: "official" }))
            .sort((a, b) => {
              const mainCountries = ["DK", "SE", "NO"];
              const isSortByAlphabet = !mainCountries.includes(a[0]) && !mainCountries.includes(b[0]);
              if (isSortByAlphabet) return a[1] > b[1] ? 1 : -1;
              return mainCountries.indexOf(a[0]) > mainCountries.indexOf(b[0]) ? -1 : 1;
            })
            .map(([k, v]) => ({
              text: `${v} (${k})`,
              value: k,
            }));
          return countryArr;
        },
      },
    ];
  }
  showFilter = false;
  filter: TableFilter[] = [];

  get numberOfFilter() {
    return Object.values(this.filter).reduce((acc, { selected }) => (acc += selected.length), 0);
  }

  @Watch("customerToEdit")
  onChangeCustomerToEdit() {
    if (!this.customerToEdit) {
      this.customerInitTab = null;
    }
  }

  @Watch("itemsPerPage")
  @Watch("sortBy")
  @Watch("page")
  async onPropertyChanged() {
    if (!this.ignoreOptionsChange) {
      await nextTick();
      this.getData();
    }
  }

  selectedHeaders = [];
  headers = [
    { title: "ID", key: "customerId" },
    { title: "First name", key: "firstName" },
    { title: "Last name", key: "lastName" },
    { title: "Email", key: "email" },
    { title: "Phone number", key: "phoneNumber" },
    { title: "Type", key: "type" },
    { title: "Company name", key: "companyName" },
    { title: "Notes", key: "notes", sortable: false },
    { title: "Created", key: "created" },
    { title: "Updated", key: "updated" },
    { title: "Country", key: "countryIso2" },
  ];

  get allowAddCustomers() {
    return userProfileService.hasPermission(UserPermissionType.AddCustomers);
  }
  get isAdmin() {
    return userProfileService.currentUser?.isAdministrator;
  }

  dataReloadTimeoutId: number | null = null;
  dataReloadIntervalSeconds = 180;
  componentActive = false;

  activated() {
    this.componentActive = true;

    if (this.initData?.customerId) {
      this.getCustomerById(this.initData?.customerId, true);
    }

    // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
    if (this.dataReloadTimeoutId === 0 || this.dataReloadTimeoutId === null) {
      this.getData();
    }
  }
  deactivated() {
    this.componentActive = false;
  }

  restartDataReloadTimeout() {
    if (this.dataReloadTimeoutId) {
      clearTimeout(this.dataReloadTimeoutId);
    }

    this.dataReloadTimeoutId = setTimeout(() => {
      this.dataReloadTimeoutId = 0;
      if (this.componentActive) {
        this.getData();
      }
    }, this.dataReloadIntervalSeconds * 1000);
  }

  contextMenuEventItem: any = null;
  openContenxMenu(e: any) {
    this.contextMenuEventItem = e;
  }

  getCustomerTypeName(type: CustomerType) {
    return CustomerHelper.getCustomerTypeDisplayName(type);
  }

  newCustomer() {
    this.customerToEdit = {
      customerId: 0,
      firstName: "",
      lastName: "",
      email: "",
      type: CustomerType.Unknown,
      companyName: "",
      createdDate: new Date(),
    } as Customer;
  }

  getCustomerById(customerId: number, isInitData = false) {
    if (customerId) {
      this.loading = true;

      customerResource
        .getCustomerById(customerId)
        .then((resp) => {
          this.customerToEdit = resp.data;
          if (isInitData) {
            this.customerInitTab = this.initData?.customerTab || null;
          }
        })
        .catch(customerResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
        });
    }
  }

  countryByCode(code: string) {
    return countries.getName(code, "en", { select: "official" });
  }

  getData(resetPagination: boolean = false) {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Reset pagination
    if (resetPagination) {
      this.ignoreOptionsChange = true;
      this.page = 1;
    }

    // Save sorting, filters and search terms
    userStorage.set(this.optionsStorageKey, {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      sortBy: this.sortBy,
    });
    userStorage.set(this.searchFieldsStorageKey, this.selectedSearchFields);
    userStorage.set(this.searchTermStorageKey, this.searchTerm);

    // Restart data reload timeout
    this.restartDataReloadTimeout();

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      let countryFilter = undefined;

      if (!this.searchTerm) {
        countryFilter = this.filter
          .find(({ filterName }) => filterName === "countryIso2")
          ?.selected.map(({ value }) => value)[0];
      }

      let searchTermValue;
      if (this.selectedSearchFields && this.searchTerm) {
        searchTermValue = this.selectedSearchFields + ":" + this.searchTerm;
      } else {
        searchTermValue = this.searchTerm;
      }
      const orderDesc =
        typeof this.sortBy[0].order === "boolean" ? this.sortBy[0].order : this.sortBy[0].order.toString() === "desc";

      customerResource
        .getCustomersPaged(
          this.itemsPerPage,
          this.page,
          searchTermValue,
          this.sortBy[0].key,
          orderDesc,
          countryFilter,
          this.cancelToken
        )
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
        })
        .catch(customerResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
          this.ignoreOptionsChange = false;
        });
    }, 10);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData(true);
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData(true);
      }, 1000);
    }
  }

  reload() {
    this.getData();
  }

  rowClick(item: Customer) {
    if (!this.contextMenuEventItem) {
      this.customerToEdit = Object.assign({}, item);
    }
  }

  rowClass({ item }: { item: Customer }) {
    return { class: { "cursor-default": true } };
  }

  exportCustomers(format: string) {
    window.open(`${commonHelper.apiHost}/export/customers-and-last-used-device-id?format=${format}`, "_blank");
  }

  updateFilter({ newFilter }: { newFilter: TableFilter[] }) {
    this.filter = newFilter;
    if (this.page > 1) {
      this.page = 1;
      return;
    }

    this.getData();
  }
}
export default toNative(Customers);
</script>
