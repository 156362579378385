<template>
  <div class="info-messages-container">
    <div class="text-caption px-1 py-0 info-messages-wrap">
      <v-alert v-for="(alert, index) in infoMsg.state.messages" :key="index" closable border="start" :type="alert.type">
        {{ alert.message }}
      </v-alert>
    </div>
  </div>
</template>

<script lang="ts">
import infoMessageService from "@/services/InfoMessageService";
import { Component, Vue, toNative } from "vue-facing-decorator";

@Component({
  components: {},
})
class InfoMessage extends Vue {
  infoMsg = infoMessageService;
}

export default toNative(InfoMessage);
</script>

<style scoped>
.info-messages-container {
  position: fixed;
  bottom: 5px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 10000;
  padding: 0 1rem;
  text-align: center;
}

.info-messages-wrap {
  z-index: 9999;
  position: absolute;
  bottom: 10px;

}
.v-alert {
  padding: 8px 16px 8px 16px;
  margin-left: 10px;
  font-size: 1rem;
  margin-top: 10px;
}
</style>
